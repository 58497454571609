import { IBoard, IOwner } from './types';

const COLOR_BY_OWNER_OPACITY = 0.2;
const COLOR_BY_OWNER = Object.freeze({
  assassin: `rgba(0, 0, 0, ${COLOR_BY_OWNER_OPACITY})`,
  blue: `rgba(0,0,255, ${COLOR_BY_OWNER_OPACITY})`,
  red: `rgba(255, 0, 0, ${COLOR_BY_OWNER_OPACITY})`,
  yellow: `rgba(255, 255, 0, ${COLOR_BY_OWNER_OPACITY})`,
}) as Record<IOwner, string>;

const Board = ({
  board,
  canClick,
  displayAllCardOwnersByColor = false,
  displayWordForRevealedCards = false,
  onClick,
}: {
  board: IBoard;
  canClick: boolean;
  displayAllCardOwnersByColor: boolean;
  displayWordForRevealedCards: boolean;
  onClick: (i: number, j: number) => void;
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {board.map((row, i) => (
        <div key={i} style={{ display: 'flex', gap: '1rem' }}>
          {row.map((card, j) => (
            <div
              key={j}
              className="board-card unselectable"
              style={{
                cursor: canClick ? 'pointer' : undefined,
                backgroundColor:
                  displayAllCardOwnersByColor || card.revealed
                    ? COLOR_BY_OWNER[card.owner]
                    : undefined,
              }}
              onClick={() => {
                canClick && onClick(i, j);
              }}
            >
              {card.revealed ? (
                displayWordForRevealedCards ? (
                  <span className="text-muted">{card.word}</span>
                ) : null
              ) : (
                <span>{card.word}</span>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default Board;

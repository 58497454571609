import { db } from '../../../../../firebase.js';
import { Id } from '../types';

// getListsCollectionRefByUid
// lists
const listsRef = ({ uid }: { uid: Id }) =>
  db
    .collection('lists')
    .doc('data')
    .collection('users')
    .doc(uid)
    .collection('lists');

// getListDocRefByUidAndListId
// lists > list
const listRef = ({ uid, listId }: { uid: Id; listId: Id }) =>
  listsRef({ uid }).doc(listId);

// getItemsCollectionRefByUidAndListId
// lists > list > items
const itemsRef = ({ uid, listId }: { uid: Id; listId: Id }) =>
  listRef({ uid, listId }).collection('items');

// getItemDocRefByUidAndListIdAndItemId
// lists > list > items > item
const itemRef = ({ uid, listId, id }: { uid: Id; listId: Id; id: Id }) =>
  itemsRef({ uid, listId }).doc(id);

// getRelationshipsCollectionRefByUidAndListId
// lists > list > relationships
const relationshipsRef = ({ uid, listId }: { uid: Id; listId: Id }) =>
  listRef({ uid, listId }).collection('relationships');

// getRelationshipDocRefByUidAndListIdAndRelationshipId
// lists > list > relationships > relationship
const relationshipRef = ({
  uid,
  listId,
  id,
}: {
  uid: Id;
  listId: Id;
  id: Id;
}) => relationshipsRef({ uid, listId }).doc(id);

export {
  itemRef,
  itemsRef,
  listRef,
  listsRef,
  relationshipRef,
  relationshipsRef,
};

import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import Card from '../../../shared/Card';

type Armor = number;
type Dmg = number;
type Hp = number;

const fighterRecipe1 = {
  armor: 1,
  dmg: 3,
  maxHp: 10,
};

class Fighter {
  alive;
  armor;
  dmg;
  hp;
  maxHp;

  constructor({ armor, dmg, maxHp }: { armor: Armor; dmg: Dmg; maxHp: Hp }) {
    this.armor = armor;
    this.dmg = dmg;
    this.maxHp = maxHp;

    this.hp = this.maxHp;

    this.alive = () => this.hp > 0;
  }
}

// this is simul attack; TODO improve
function fight(fighters: Fighter[] = []) {
  const log = [];

  let currentAttackerIdx = 0;
  function defenderIdx() {
    // this only works for 1 vs 1
    return currentAttackerIdx === 0 ? 1 : 0;
  }

  let continueFight = true;
  while (continueFight) {
    const damageDealt =
      fighters[currentAttackerIdx].dmg - fighters[defenderIdx()].armor;
    fighters[defenderIdx()].hp -= damageDealt;
    log.push(
      `fighter ${defenderIdx()}: -${damageDealt} hp (${
        fighters[defenderIdx()].hp
      } hp)`
    );
    console.log(defenderIdx(), ': ', fighters[defenderIdx()].hp);

    if (fighters[defenderIdx()].hp <= 0) {
      continueFight = false;
    }

    currentAttackerIdx = defenderIdx();
  }

  return log;
}

type FightSimData = {
  fighters: Fighter[];
  log: React.ReactNode[];
};

function FightSim() {
  const [data, setData] = useState<FightSimData>({} as FightSimData);

  useEffect(() => {
    const a = new Fighter(fighterRecipe1);
    const b = new Fighter(fighterRecipe1);
    setData({ ...data, fighters: [a, b] });
  }, []);

  function onClick() {
    const log = fight(data.fighters);
    setData({ ...data, log });
  }

  return (
    <Card title="Fight Sim">
      <div style={{ display: 'flex', gap: '1rem' }}>
        {data.fighters?.map((fighter) => (
          <Card>
            <Table style={{ marginBottom: 0 }}>
              <tbody>
                <tr>
                  <td>armor</td>
                  <td>{fighter.armor}</td>
                </tr>
                <tr>
                  <td>dmg</td>
                  <td>{fighter.dmg}</td>
                </tr>
                <tr>
                  <td>hp</td>
                  <td>{fighter.hp}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        ))}
      </div>
      <Card>
        <Button onClick={onClick}>Fight!</Button>
      </Card>
      <Card title="Log">
        <Table>
          <tbody>
            {data.log?.map((entry) => (
              <tr>
                <td>{entry}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </Card>
  );
}

export default FightSim;

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from '../../../../../shared/Card';
import { styleVariantFromTeamColor } from './utils';
import { IClue, TeamColor } from './types';

export const ClueCard = ({
  clue,
  teamColor,
  guessesMadeThisTurn,
}: {
  clue: IClue;
  teamColor: TeamColor;
  guessesMadeThisTurn: number;
}) => {
  if (!clue) {
    return null;
  }
  const { word, number } = clue;
  const guessesRemaining = number - guessesMadeThisTurn;

  return (
    <Card title="Clue" bg={styleVariantFromTeamColor(teamColor)}>
      <ListGroup>
        <ListGroup.Item style={{ fontSize: 'max(2.5vw, 1rem)' }}>
          <strong>Word:</strong> {word}
        </ListGroup.Item>
        <ListGroup.Item style={{ fontSize: 'max(2.5vw, 1rem)' }}>
          <strong>Number:</strong> {number}
        </ListGroup.Item>
        <ListGroup.Item style={{ fontSize: 'max(2.5vw, 1rem)' }}>
          <strong>Guesses Remaining:</strong> {guessesRemaining} + 1
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const stringContainsWhitespace = (str: string) => /\s/g.test(str);

export const ClueForm = ({
  submitClue,
}: {
  submitClue: (clue: IClue) => void;
}) => {
  const [clue, setClue] = useState<IClue>();
  return (
    <Card title="Give Clue">
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>one-word clue</Form.Label>
          <Form.Control
            type="text"
            placeholder="one-word"
            value={clue?.word}
            onChange={(e) => {
              const word = e.target.value;
              if (!stringContainsWhitespace(word)) {
                // @ts-ignore-next-line TODO fix this
                setClue({ ...clue, word });
              }
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>number</Form.Label>
          <Form.Control
            type="number"
            placeholder="#"
            value={clue?.number}
            onChange={(e) => {
              const number = Number(e.target.value);
              if (number >= 0) {
                // @ts-ignore-next-line TODO fix this
                setClue({ ...clue, number });
              }
            }}
          />
        </Form.Group>
        <Button
          onClick={() => {
            // @ts-ignore-next-line TODO fix this
            submitClue(clue);
          }}
        >
          Submit Clue
        </Button>
      </Form>
    </Card>
  );
};

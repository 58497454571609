import { Badge, Button, ButtonGroup, Form, Tab, Tabs } from 'react-bootstrap';
import Card from '../../../../shared/Card';
import DeleteButton from './DeleteButton';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  deleteItemAndRelationships,
  deleteItemRelationships,
  deleteRelationship,
  getItems,
  getRelationships,
  upsertItem,
  upsertRelationship,
} from './api/items-and-relationships-api';
import HierarchyDisplay from './hierarchy-display/HierarchyDisplay';
import CompareItemsForm from './CompareForm';
import RelationshipsList from './Relationships';
import AppContext from '../../../../AppContext';
import { Id } from './types';
import { createList, deleteList } from './api/lists-api';

export const DEFAULT_LIST_ID = 'my values';

// TODO probably don't need this probably redundant id
const NEW_ITEM = Object.freeze({ id: '' });
const AddItemForm = ({ onSubmit }) => {
  const [newItem, setNewItem] = useState(NEW_ITEM);
  const clearNewItem = () => setNewItem(NEW_ITEM);
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({ item: newItem, onSuccess: clearNewItem });
      }}
    >
      <ButtonGroup style={{ width: '100%' }}>
        <Form.Control
          type="text"
          placeholder="item name"
          value={newItem.id}
          onChange={(e) => {
            setNewItem({ ...newItem, id: e.target.value });
          }}
          autoFocus={true}
        />
        <Button type="submit" style={{ whiteSpace: 'nowrap' }}>
          Add Item
        </Button>
      </ButtonGroup>
    </Form>
  );
};

const DeleteListButton = ({ onClick }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button variant="light" size="sm" onClick={() => setShow(!show)}>
        ...
      </Button>
      {show && (
        <Button
          variant="danger"
          size="sm"
          className="float-end"
          onClick={() => {
            onClick();
            setShow(false);
          }}
        >
          DELETE LIST
        </Button>
      )}
    </>
  );
};

export default function Editor({
  listId,
  lists,
  loadLists,
  selectListId,
}: {
  listId: Id;
  lists: object;
  loadLists: () => void;
  selectListId: (listId: Id) => void;
}) {
  const { user } = useContext(AppContext);

  const [items, setItems] = useState({});
  const [relationships, setRelationships] = useState({});
  const [itemsToCompare, setItemsToCompare] = useState([]);

  const list = useMemo(() => (lists && lists[listId]) || {}, [listId, lists]);

  const loadItems = () =>
    listId &&
    getItems(user.uid, listId, (items) => {
      setItems(items);
    });
  const loadRelationships = () =>
    listId &&
    getRelationships(user.uid, listId, (relationships) => {
      setRelationships(relationships);
    });

  useEffect(() => {
    if (!user) return;
    // TODO could use getListIds instead of this monolith for long-term lightness
    // do not go the other direction and monolith the calls in this useEffect
    // OR ACTUALLY could display useful and beautiful stats etc w this lists data beheamoth
    loadLists((lists) => {
      // TODO probably shouldn't do this
      // if no lists exist but this page has a list id, create one
      if ((!lists || lists.length < 1) && listId) {
        createList(user.uid, listId, () => loadLists());
      }
    });
    loadItems();
    loadRelationships();
  }, [listId]);

  const handleDeleteItem = (itemId) =>
    deleteItemAndRelationships(user.uid, listId, itemId, () => {
      loadItems();
      loadRelationships();
    });

  const handleDeleteRelationships = (itemId) =>
    deleteItemRelationships(user.uid, listId, itemId, loadRelationships);

  return (
    <Card>
      {/* TODO shouldn't need mt-3 here, cleanup the organization contiguous with the above (eg us tabs for the below) */}
      <h1 className="mt-3">
        {list?.name}
        <span className="ms-2">
          {/* TODO add a confirm delete */}
          <DeleteListButton
            onClick={() =>
              deleteList(user.uid, listId, () => {
                loadLists();
                // TODO bug if DEFAULT_LIST_ID doesn't exist?  should just deselect list instead of this
                selectListId(DEFAULT_LIST_ID);
              })
            }
          />
        </span>
      </h1>
      <pre>list.name: {list?.name}</pre>
      <Tabs defaultActiveKey="main" className="my-3">
        <Tab eventKey="main" title="Main">
          <div className="my-4">
            <h2>Hiearchy Display</h2>
            <p>
              Display of your existing items organized by relationships from
              items you've compared.
            </p>
            <Card>
              <HierarchyDisplay
                deleteItem={handleDeleteItem}
                deleteRelationships={handleDeleteRelationships}
                items={items}
                relationships={relationships}
                addItemForCompare={(id) => {
                  setItemsToCompare([items[id], ...itemsToCompare].slice(0, 2));
                }}
              />
            </Card>
          </div>
          <div className="my-4">
            <h2>Add Item</h2>
            <AddItemForm
              onSubmit={({ item, onSuccess = () => null }) =>
                upsertItem(user.uid, listId, item, () => {
                  loadItems();
                  onSuccess();
                })
              }
            />
          </div>

          <div className="my-4">
            <h2>Compare Items / Add Relationship</h2>
            <p>
              Choose which item is more. Of what is arbitrary. It is up to you.
              I'm usually measuring something like importance to me.
            </p>
            <p>
              Comparing items creates relationships. Comparing two items creates
              a relationship. Relationship data are used to organize your item
              for the hierarchy display. The hierarchy display is a helpful way
              of understanding the rank groups of your items. I find this
              particularly useful for understanding priorities. It empowers you
              to discover and work with your priority values, affairs, goals,
              activities, habits, preferences, tasks, etc.
            </p>
            <Card>
              {items && Object.keys(items).length >= 2 && (
                <CompareItemsForm
                  items={items}
                  itemsToCompare={itemsToCompare}
                  relationships={relationships}
                  addRelationship={(relationship, onSuccess = () => null) =>
                    upsertRelationship(user.uid, listId, relationship, () => {
                      loadRelationships();
                      onSuccess();
                    })
                  }
                />
              )}
            </Card>
          </div>
        </Tab>
        <Tab eventKey="hierarchy" title="Hierarchy">
          <HierarchyDisplay
            deleteItem={handleDeleteItem}
            deleteRelationships={handleDeleteRelationships}
            items={items}
            relationships={relationships}
            addItemForCompare={(id) => {
              setItemsToCompare([items[id], ...itemsToCompare].slice(0, 2));
            }}
          />
        </Tab>
        <Tab eventKey="compare" title="Compare">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
            }}
          >
            <div>
              <CompareItemsForm
                items={items}
                itemsToCompare={itemsToCompare}
                relationships={relationships}
                addRelationship={(relationship, onSuccess = () => null) =>
                  upsertRelationship(user.uid, listId, relationship, () => {
                    loadRelationships();
                    onSuccess();
                  })
                }
              />
            </div>
            <div>
              {/* TODO would be nice if this HiearchyDisplay just automatically did addToCompareQueue onClick in the context of this "Compare" tab */}
              <HierarchyDisplay
                deleteItem={handleDeleteItem}
                deleteRelationships={handleDeleteRelationships}
                items={items}
                relationships={relationships}
                addItemForCompare={(id) => {
                  setItemsToCompare([items[id], ...itemsToCompare].slice(0, 2));
                }}
              />
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="items"
          title={
            <>
              Items
              {items && Object.keys(items).length > 0 && (
                <Badge className="ms-2">{Object.keys(items).length}</Badge>
              )}
            </>
          }
        >
          <Card title="Items">
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              <AddItemForm
                onSubmit={({ item, onSuccess = () => null }) =>
                  upsertItem(user.uid, listId, item, () => {
                    loadItems();
                    onSuccess();
                  })
                }
              />

              <div>
                {!items || Object.keys(items).length < 1 ? (
                  <pre className="text-info">Add some items</pre>
                ) : (
                  Object.values(items).map((item) => (
                    <div key={item.id} className="my-1">
                      {item.id}
                      <DeleteButton onClick={() => handleDeleteItem(item.id)} />
                      <DeleteButton
                        onClick={() => handleDeleteRelationships(item.id)}
                      >
                        Delete Relationships
                      </DeleteButton>
                    </div>
                  ))
                )}
              </div>
            </div>
          </Card>
        </Tab>
        <Tab
          eventKey="relationships"
          title={
            <>
              Relationships
              {relationships && Object.keys(relationships).length > 0 && (
                <Badge className="ms-2">
                  {Object.keys(relationships).length}
                </Badge>
              )}
            </>
          }
        >
          <Card className="mt-3" title="Relationships">
            {!items || Object.keys(items).length < 2 ? (
              <pre className="text-info">Requires 2 or more items</pre>
            ) : (
              <RelationshipsList
                relationships={relationships}
                deleteRelationship={({ id }) =>
                  deleteRelationship(user.uid, listId, id, () => {
                    loadRelationships();
                  })
                }
              />
            )}
          </Card>
        </Tab>
      </Tabs>
    </Card>
  );
}

import { useContext, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import AppContext from '../../../../../AppContext';
import Card from '../../../../../shared/Card';
import { User } from '../../../../../shared/User';
import { updateGameUser } from '../../api';
import { IGameCodeNames, IRole, TeamColor } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseye,
  faEye,
  faHatWizard,
  faMousePointer,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { styleVariantFromTeamColor } from './utils';

/**
 * @constant teams
 * @type {Array({id: TeamColor, name: string})}
 */
const teams = [
  {
    id: 'red',
    name: 'Red',
  },
  { id: 'blue', name: 'Blue' },
  { id: 'yellow', name: 'Yellow' },
];

function IconFromRole({ role }: { role: IRole }) {
  if (role === 'clue-giver') {
    return (
      <>
        <FontAwesomeIcon icon={faEye} />
        <FontAwesomeIcon icon={faHatWizard} />
      </>
    );
  }
  if (role === 'guesser') {
    return (
      <>
        <FontAwesomeIcon icon={faMousePointer} />
        <FontAwesomeIcon icon={faBullseye} />
      </>
    );
  }
  return null;
}

const MyCodeNamesUser = ({ role, team }: { role: IRole; team: TeamColor }) => {
  const { user } = useContext(AppContext);
  return (
    <Card bg={styleVariantFromTeamColor(team)}>
      <h5>
        <User
          displayName={user?.displayName}
          noLinkName={true}
          uid={user?.uid}
        />
      </h5>
      {/* TODO stop abusing components simply for color style */}
      <ListGroup>
        <ListGroup.Item variant={styleVariantFromTeamColor(team)}>
          {team} <IconFromRole role={role} /> {role}
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

// TODO game should be up in a context
// TODO gameId should be on game object
// TODO gameUser should be up in a context
function JoinTeamButton({
  disabled,
  game,
  gameId,
  id,
  role,
}: {
  disabled: boolean;
  game: IGameCodeNames;
  gameId: string;
  id: string;
  role: IRole;
}) {
  const { user } = useContext(AppContext);

  const gameUser = useMemo(
    // @ts-ignore-next-line TODO fix this
    () => game?.users && game?.users[user?.uid],
    [game, user]
  );

  if (typeof user === 'undefined') return;

  const joinTeam = (teamColor: TeamColor | null, role: IRole) => {
    // TODO untangle this whacky api
    updateGameUser(gameId, user.uid, {
      displayName: user.displayName,
      // @ts-ignore-next-line TODO fix this
      role,
      team: teamColor,
    });
  };

  return (
    <Button
      disabled={disabled}
      // TODO this seems legitimately broken/bug
      onClick={() => joinTeam(id, role)}
      size="sm"
      variant={gameUser ? 'secondary' : 'primary'}
    >
      <FontAwesomeIcon icon={faPlus} />
    </Button>
  );
}

export default function TeamAndRole({
  game,
  gameId,
  isUser,
  userRole,
  userTeam,
}: {
  game: IGameCodeNames;
  gameId: string;
  // TODO this is absurd, extract and import, don't take as prop
  // TODO better yet, just receive the prop as a boolean
  isUser: (_: IRole | TeamColor) => boolean;
  userRole: IRole;
  userTeam: TeamColor;
}) {
  return (
    <Card title="Team Role">
      <div className="mb-3">
        <p>You are...</p>
        <MyCodeNamesUser role={userRole} team={userTeam} />
      </div>
      <p>Choose a team role...</p>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
        {teams.map((team) => (
          <Card
            bg={
              team.id === 'red'
                ? 'danger'
                : team.id === 'blue'
                ? 'primary'
                : team.id === 'yellow'
                ? 'warning'
                : undefined
            }
            key={team.id}
            title={`${team.name} Team`}
            style={{ flexGrow: 1 }}
          >
            <div className="mb-1">
              <IconFromRole role={'clue-giver'} /> Clue-Giver
            </div>
            <div className="mb-3">
              <ListGroup>
                {game?.users &&
                  Object.entries(game.users)
                    .filter(
                      ([key, user]) =>
                        user.team === team.id && user.role === 'clue-giver'
                    )
                    .map(([key, user]) => (
                      <ListGroup.Item key={key}>
                        <User
                          uid={key}
                          displayName={user.displayName}
                          noLinkName={true}
                        />
                      </ListGroup.Item>
                    ))}
                {/* @ts-ignore-next-line TODO fix this */}
                {!(isUser(team.id) && isUser('clue-giver')) && (
                  <ListGroup.Item>
                    <JoinTeamButton
                      disabled={game?.phase !== 'setup'}
                      game={game}
                      gameId={gameId}
                      id={team.id}
                      role={'clue-giver'}
                    />
                  </ListGroup.Item>
                )}
              </ListGroup>
            </div>
            <div className="mb-1">
              <IconFromRole role={'guesser'} /> Guesser
            </div>
            <div>
              <ListGroup>
                {game?.users &&
                  Object.entries(game.users)
                    .filter(
                      ([key, user]) =>
                        user.team === team.id && user.role === 'guesser'
                    )
                    .map(([key, user]) => (
                      <ListGroup.Item key={key}>
                        <User
                          uid={key}
                          displayName={user.displayName}
                          noLinkName={true}
                        />
                      </ListGroup.Item>
                    ))}
                {/* @ts-ignore-next-line TODO fix this */}
                {!(isUser(team.id) && isUser('guesser')) && (
                  <ListGroup.Item>
                    <JoinTeamButton
                      disabled={game?.phase !== 'setup'}
                      game={game}
                      gameId={gameId}
                      id={team.id}
                      role={'guesser'}
                    />
                  </ListGroup.Item>
                )}
              </ListGroup>
            </div>
          </Card>
        ))}
      </div>
    </Card>
  );
}

import React, { useEffect, useState } from 'react';
import { getUser, getUsers } from '../api/users';
import {
  login as userAuthLogin,
  logoff as userAuthLogoff,
} from './App/userAuth';
import firebase, { auth } from './firebase.js';
import getROOMS from './views/Rooms/getROOMS';

type FirebaseTimestamp = {
  seconds: number;
  nanoseconds: number;
};

type Room = {
  id: string;
  posts: object[];
  requires: string[];
  title: string;
  url: string;
};
type Rooms = { [roomId: string]: Room };

type CommonUserType = {
  displayName: string;
  email: string;
  isPremium: boolean;
  joined: FirebaseTimestamp;
  lastLogin: FirebaseTimestamp;
  photoURL: string;
  uid: string;
};

export type UserType = CommonUserType & {
  admin: boolean;
  following: string[];
  lastOnline: number;
  premium: FirebaseTimestamp;
  presence: string;
  username: string;
};

type AppContextType = {
  loadingUser: boolean;
  login: () => void;
  logout: () => void;
  modality: any;
  setModality: any;
  rooms: Rooms;
  user: UserType | undefined;
  users: CommonUserType[];
  showWizard: boolean;
  setShowWizard: any;
  toggleShowWizard: () => void;
};

const AppContext = React.createContext<AppContextType>({
  user: undefined,
} as AppContextType);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [modality, setModality] = useState(null);
  const [rooms, setRooms] = useState<Rooms>({});
  const [showWizard, setShowWizard] = useState(true);
  const [user, setUser] = useState<UserType | undefined>();
  const [users, setUsers] = useState<CommonUserType[]>([]);

  const login = () => {
    userAuthLogin((user: UserType) => {
      setUser(user);
      setLoadingUser(false);
    });
  };

  const logout = () => {
    userAuthLogoff(() => {
      setUser(undefined);
    });
    //TODO redirect to home
  };

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        const uid = authUser.uid;

        getUser(uid, (user: UserType) => {
          setUser(user);
          setLoadingUser(false);
        });

        getUsers((users: CommonUserType[]) => {
          setUsers(users);
        });

        // TODO combine these w an update instead of a set

        // onDisconnect is a feature of firebase realtime database
        // this is the only valid use of the realtime database users collection atm
        // all other user data should be handled in firebase cloud firestore
        firebase
          .database()
          .ref('users/' + uid + '/lastOnline')
          .onDisconnect()
          .set(firebase.database.ServerValue.TIMESTAMP)
          .then(() => {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
            firebase
              .database()
              .ref('users/' + uid + '/lastOnline')
              .set(firebase.database.ServerValue.TIMESTAMP);
          });
        firebase
          .database()
          .ref('users/' + uid + '/presence')
          .onDisconnect()
          .set('offline')
          .then(() => {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            firebase
              .database()
              .ref('users/' + uid + '/presence')
              .set('online');
          });
      } else {
        setLoadingUser(false);
      }
    });

    getROOMS((rooms: Rooms) => {
      setRooms(rooms);
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        loadingUser,
        login,
        logout,
        modality,
        setModality,
        rooms,
        user,
        users,
        showWizard,
        setShowWizard,
        toggleShowWizard: () => setShowWizard(!showWizard),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;

import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Game from './Game';
import Games from './Games';
import Page from '../../Page';

const GamesRouter = () => {
  const location = useLocation();

  return (
    <Switch>
      <Route exact path="/games" key={location.pathname}>
        <Page loginRequired={false} title="Games | xBook">
          <Games />
        </Page>
      </Route>
      <Route
        path="/games/:id"
        key={location.pathname}
        render={(props) => (
          <Page loginRequired={false} title="Game | xBook">
            <Game id={props.match.params.id} />
          </Page>
        )}
      />
    </Switch>
  );
};
export default GamesRouter;

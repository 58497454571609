import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import getItemIdsByRank from './getItemsByRank';

import './HierarchyDisplay.css';
import { toast } from 'react-toastify';

// https://stackoverflow.com/a/59554079/1438029
const useKeyPress = (targetKey) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  const downHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  };

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
};

// TODO onClick should actually open an Item info details card
const Item = ({
  addItemForCompare,
  children,
  deleteItem,
  deleteRelationships,
}: {
  addItemForCompare: () => void;
  children: React.ReactNode;
  deleteItem: () => void;
  deleteRelationships: () => void;
}) => {
  const [mouseOver, setMouseOver] = useState<boolean>();
  const isMetaKeyDown = useKeyPress('Meta');

  return (
    <div
      style={{ display: 'inline-block' }}
      className="mt-1 me-2 mb-2 ms-1"
      onClick={(e) => {
        if (e.metaKey) {
          addItemForCompare();
        }
      }}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <DropdownButton
        className="dropdown-toggle-no-arrow"
        disabled={isMetaKeyDown}
        title={children}
        variant={mouseOver ? 'primary' : 'light'}
      >
        <Dropdown.Item onClick={addItemForCompare} className="text-primary">
          <FontAwesomeIcon icon={faCompressAlt} className="fa-fw me-2" />
          Compare
        </Dropdown.Item>
        <Dropdown.Divider />
        {/* TODO fix is dangerous without a confirmation popup */}
        <Dropdown.Item onClick={deleteRelationships} className="text-warning">
          <FontAwesomeIcon icon={faTrashAlt} className="fa-fw me-2" />
          Delete Relationships
        </Dropdown.Item>
        {/* TODO fix is dangerous without a confirmation popup */}
        <Dropdown.Item onClick={deleteItem} className="text-danger">
          <FontAwesomeIcon icon={faTrashAlt} className="fa-fw me-2" />
          Delete Item &amp; Relationships
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

const HierarchyDisplay = ({
  addItemForCompare,
  deleteItem,
  deleteRelationships,
  items,
  relationships,
}: {
  addItemForCompare: (itemId: string) => void;
  deleteItem: (itemId: string) => void;
  deleteRelationships: (itemId: string) => void;
  // TODO do better than Object here, create and use Item type
  items: { [itemId: string]: Object };
  // TODO do better than Object here, create and use Relationship type
  relationships: { [relationshipId: string]: Object };
}) => {
  const { itemIds, errorMessage } = getItemIdsByRank(relationships, items);

  if (errorMessage) {
    toast(errorMessage);
    return (
      <Item>
        <span style={{ color: 'red' }}>{errorMessage}</span>
      </Item>
    );
  }
  return Object.entries(itemIds)
    .reverse()
    .map(([rank, _items], i) => {
      return (
        <div
          key={rank}
          className="mb-1"
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          }}
        >
          <span>{i + 1}.</span>
          {_items.map((itemId) => (
            <Item
              key={itemId}
              addItemForCompare={() => addItemForCompare(itemId)}
              deleteItem={() => deleteItem(itemId)}
              deleteRelationships={() => deleteRelationships(itemId)}
            >
              {itemId}
            </Item>
          ))}
        </div>
      );
    });
};

export default HierarchyDisplay;

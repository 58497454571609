import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCalendarPlus,
  faChartBar,
  faEyeSlash,
  faFeatherAlt,
  faGamepad,
  faPersonBooth,
  faTheaterMasks,
  faUser,
  faUsers,
  faHandHoldingHeart,
  IconDefinition,
  faGraduationCap,
  faGlobe,
  faChessBoard,
  faList,
  faUserSecret,
  faBrain,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Card from '../../shared/Card';
import { Beta } from '../../shared/icons';

const ButtonLink = (props: {
  children: React.ReactNode;
  to: string;
  [prop: string]: any;
}) => {
  const history = useHistory();
  const { children, to } = props;
  return (
    <Button onClick={() => history.push(to)} {...props}>
      {children}
    </Button>
  );
};

const ResponsiveFlex = (props: {
  children: React.ReactNode;
  [prop: string]: any;
}) => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }} {...props}>
    {props.children}
  </div>
);

const FlexItem = (props: {
  children: React.ReactNode;
  [prop: string]: any;
}) => (
  <div
    style={{ flexBasis: 0, flexGrow: 1, minWidth: 300 }}
    className="m-3"
    {...props}
  >
    {props.children}
  </div>
);

const IconBullet = (props: {
  children: React.ReactNode;
  icon: IconDefinition;
  [prop: string]: any;
}) => (
  <li>
    <FontAwesomeIcon className="fa-fw me-2" {...props} />
    {props.children}
  </li>
);

function IndexStyledButtonLink({
  linkTo,
  color = '',
  renderButtonFAIcon,
  buttonLabel,
  children,
}: React.PropsWithChildren<{
  linkTo: string;
  color?: string;
  renderButtonFAIcon: IconDefinition;
  buttonLabel: string;
}>) {
  return (
    <>
      <ButtonLink
        to={linkTo}
        style={{
          width: '100%',
          backgroundColor: color,
          borderColor: color,
        }}
      >
        <div
          style={{
            fontSize: '200%',
          }}
        >
          <FontAwesomeIcon className="fa-fw me-2" icon={renderButtonFAIcon} />
          <br />
          {buttonLabel}
        </div>
      </ButtonLink>
      <div className="mt-2 text-muted">{children}</div>
    </>
  );
}

function IndexStyledButtonLinkPublic() {
  return (
    <IndexStyledButtonLink
      linkTo="/public"
      color="hotpink"
      renderButtonFAIcon={faHandHoldingHeart}
      buttonLabel="Healthy Relating"
    >
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
        <IconBullet icon={faUsers}>Public Forum</IconBullet>
        <IconBullet icon={faGraduationCap}>Modality Training</IconBullet>
        <IconBullet icon={faPersonBooth}>Topic rooms</IconBullet>
        <IconBullet icon={faChartBar}>
          Community statistics and leaderboards
        </IconBullet>
        <IconBullet icon={faCalendarPlus}>Events</IconBullet>
        {/* <IconBullet icon={faSnowplow}>
Slow to load but functional
</IconBullet> */}
      </ul>
    </IndexStyledButtonLink>
  );
}

function IndexStyledButtonLinkPrivate() {
  return (
    <IndexStyledButtonLink
      linkTo="/private"
      color="rebeccapurple"
      renderButtonFAIcon={faBook}
      buttonLabel="Private Writing"
    >
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
        <IconBullet icon={faEyeSlash}>Private journaling</IconBullet>
        <IconBullet icon={faChartBar}>
          Word count and frequency analysis
        </IconBullet>
        <IconBullet icon={faTheaterMasks}>Sentiment analysis</IconBullet>
        <IconBullet icon={faFeatherAlt}>
          Full screen zen writing mode
        </IconBullet>
      </ul>
    </IndexStyledButtonLink>
  );
}

function IndexStyledButtonLinkGames() {
  return (
    <IndexStyledButtonLink
      linkTo="/games"
      renderButtonFAIcon={faGamepad}
      buttonLabel="Games"
    >
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
        <IconBullet icon={faPersonBooth}>
          Multiplayer Lobby and Game Rooms
        </IconBullet>
        <IconBullet icon={faGlobe}>
          Shared Single Instance Multiplayer
        </IconBullet>
        <IconBullet icon={faUser}>Solitaire Solo-Play</IconBullet>
      </ul>
    </IndexStyledButtonLink>
  );
}

function IndexStyledButtonLinkCodeNames() {
  return (
    <IndexStyledButtonLink
      linkTo="/games"
      renderButtonFAIcon={faChessBoard}
      buttonLabel="Clue Sweeper"
    >
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
        <IconBullet icon={faUserSecret}>Codenames</IconBullet>
        <IconBullet icon={faUsers}>Team Building</IconBullet>
        <IconBullet icon={faBrain}>Mind Bender</IconBullet>
      </ul>
    </IndexStyledButtonLink>
  );
}

function IndexStyledButtonLinkLists() {
  return (
    <IndexStyledButtonLink
      linkTo="/lists"
      color="rebeccapurple"
      renderButtonFAIcon={faList}
      buttonLabel={`Lists`}
    >
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
        <IconBullet icon={faUser}>Self Discovery</IconBullet>
        <IconBullet icon={faList}>Priotiries Clarified</IconBullet>
      </ul>
    </IndexStyledButtonLink>
  );
}

const IndexPageMainMenuItemMedium = {
  Public: IndexStyledButtonLinkPublic,
  Private: IndexStyledButtonLinkPrivate,
  Games: IndexStyledButtonLinkGames,
  CodeNames: IndexStyledButtonLinkCodeNames,
  Lists: IndexStyledButtonLinkLists,
};

// TODO: allow user to set their own preferred home page!  this dashboard | public | private | games
const Index = () => (
  <Card>
    <ResponsiveFlex>
      <FlexItem>
        <IndexPageMainMenuItemMedium.Private />
      </FlexItem>
      <FlexItem>
        <IndexPageMainMenuItemMedium.Lists />
      </FlexItem>
      <FlexItem>
        <IndexPageMainMenuItemMedium.Games />
      </FlexItem>
      <FlexItem>
        <IndexPageMainMenuItemMedium.CodeNames />
      </FlexItem>
      <FlexItem>
        <IndexPageMainMenuItemMedium.Public />
      </FlexItem>
    </ResponsiveFlex>
  </Card>
);

export default Index;

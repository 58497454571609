import React from 'react';
import RBCard, { CardProps as RBCardProps } from 'react-bootstrap/Card';

interface CardProps extends RBCardProps {
  loading?: boolean;
  titleMB0?: boolean;
  title?: string;
}

const Card = ({
  children,
  loading = false,
  titleMB0 = false,
  title = '',
  ...props
}: React.PropsWithChildren<CardProps>) => (
  <RBCard className="mb-3" {...props}>
    <RBCard.Body>
      {title && (
        <RBCard.Title className={titleMB0 ? 'mb-0' : ''}>{title}</RBCard.Title>
      )}
      {children}
    </RBCard.Body>
  </RBCard>
);

export default Card;

import { db } from '../components/firebase.js';

export const getEvents = (callback) => {
  const events = {};
  db.collection('events')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        events[doc.id] = doc.data();
        events[doc.id].id = doc.id;
      });
      callback(events);
    });
};
export const getEvent = ({ eventId }, callback) => {
  db.collection('events')
    .doc(eventId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const event = doc.data();
        event.id = doc.id;
        callback(event);
      }
    });
};
//TODO works for create. does this work for update?
export const upsertEvent = (event, callback) => {
  const eventRef = db.collection('events').doc(event.id);
  db.collection('events')
    .doc(eventRef.id)
    .set(event, { merge: true })
    .then(() => {
      callback && typeof callback === 'function' && callback(eventRef.id);
    });
};
export const updateEvent = (id, event, callback) => {
  db.collection('events')
    .doc(id)
    .update(event)
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};

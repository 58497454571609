import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IGame, IGameStatus, onGame } from './Lobby/api';
import CodeNames from './Lobby/Games/CodeNames';
import { cast } from '../../../utils/cast';
import AppContext from '../../AppContext';

export const variantFromGameStatus = (status: IGameStatus) => {
  if (status === 'setup') {
    return 'success'; // green
  }
  if (status === 'playing') {
    return 'warning'; // yellow
  }
  if (status === 'done') {
    return 'danger'; // red
  }
};

const Game = ({ id }: { id: string }) => {
  const [game, setGame] = useState<IGame>();
  const { user } = useContext(AppContext);

  // TODO could pass the whole game object to the CodeNames component instead of doubling this
  useEffect(() => {
    onGame(id, (game) => setGame(cast<IGame>(game)));
  }, [id]);

  if (game?.type === 'codenames') {
    if (typeof user !== 'undefined') {
      return <CodeNames id={id} user={user} />;
    }
    return <>codenames requires user login</>;
  }

  return (
    <>
      <div className="ms-3 mb-2 small">
        <Link to="/games">&laquo; games</Link>
      </div>
      {/* TODO handle unrecognized game types */}
    </>
  );
};

export default Game;

import { useContext, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { toast } from 'react-toastify';
import AppContext, { UserType } from '../../../../../AppContext';
import Card from '../../../../../shared/Card';
import { IGame, onGame, updateGame } from '../../api';
import Board from './Board';
import { ClueCard, ClueForm } from './Clue';
import Settings from './Settings';
import TeamAndRole from './TeamAndRole';
import {
  GamePhase,
  IBoard,
  ICard,
  IGameCodeNames,
  IRole,
  TeamColor,
} from './types';
import {
  getEmojis,
  getRandomArrayElements,
  getWords,
  isGameOver,
  nextGamePhase,
  styleVariantFromGamePhase,
  styleVariantFromTeamColor,
  teamColorFromGamePhase,
  teamRoleFromGamePhase,
} from './utils';

import './index.css';
import { Tab, Tabs } from 'react-bootstrap';
import { variantFromGameStatus } from '../../../Game';
import { cast } from '../../../../../../utils/cast';

/**
 * - TODO codenames context for game object and gameUser etc
 *
 * - TODO if a team guesses the assassin in more than 2 team game, "undefined Team Wins!"
 * - TODO if in a more than 2 team game, if team guesses assasin they lose but game can continue, don't show all the other cards obviously
 * - TODO continuing when third team guesses assassin could be toggleable
 * - TODO add toggle or whatever for third or more teams
 *
 * - TODO more detailed feedback about who won and why
 * - TODO more detailed feedback about why a phase changed or turn ended
 * - WANT pictures
 * - EVENTUALLY extract framework and reusable components for more game types
 * - EVENTUALLY cleanup the board-card with vw vh max and stacking for tiny screen widths etc
 * - PROBABLY improve only valid clues can be submitted
 * - MAYBE show teams and scores etc
 * - MAYBE log clues and guesses
 * - MAYBE red isn't necessarily the first team
 * - MAYBE team variable colors, names
 * - MAYBE optional turn timers
 * - MAYBE audio clues
 * - MAYBE gif cards
 * - MAYBE audio cards!
 * - MAYBE EVENTUALLY backend logic to reduce ease of cheating
 * - TODO 2022-11-23 bug where two people submitted guess at same time and one showed then hid and other showed and committed
 *
 * - allow someone to give clues for multiple teams
 * - 2 player mode(s)
 *
 * More Suggestions
 * - be able to see the most recently covered card until whenever's clever
 * - be able to join as a guesser after a game starts
 * - pop-up to quick/easy-join when a new game is created
 */

const PhaseDisplay = ({
  phase,
  winner,
}: {
  phase: GamePhase;
  winner: TeamColor | undefined;
}) => {
  const variant = styleVariantFromGamePhase(phase, winner);
  return (
    <Card bg={styleVariantFromGamePhase(phase, winner)}>
      <ListGroup>
        <ListGroup.Item variant={variant}>
          <strong>Phase:</strong> {phase}
        </ListGroup.Item>
        {phase !== 'game-over' && (
          <ListGroup.Item variant={variant}>
            <strong>Waiting for:</strong>{' '}
            <em>
              {phase === 'setup' &&
                'settings (optional), Team & Role selection (required), and Start Game'}
              {phase === 'first-team-clue' && 'red team: clue'}
              {phase === 'first-team-guessing' && 'red team: guess or end turn'}
              {phase === 'second-team-clue' && 'blue team: clue'}
              {phase === 'second-team-guessing' &&
                'blue team: guess or end turn'}
              {phase === 'third-team-clue' && 'yellow team: clue'}
              {phase === 'third-team-guessing' &&
                'yellow team: guess or end turn'}
              {'...'}
            </em>
          </ListGroup.Item>
        )}
        {winner && (
          <ListGroup.Item variant={variant}>
            <strong>Winner:</strong> {winner} team
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
};

const AdminNextPhaseButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    className="mt-3"
    variant="danger"
    onClick={() => {
      window.confirm('Admin confirm force next phase?') && onClick();
    }}
  >
    Admin Next Phase
  </Button>
);

const EndTurnButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    className="mt-3"
    variant="warning"
    onClick={() => {
      window.confirm('End your turn?') && onClick();
    }}
  >
    Pass / End Turn
  </Button>
);

const DEFAULT_ALWAYS_SHOW_WORDS = false;

const DEFAULT_ROWS = 4;
const DEFAULT_COLS = 5;

const DEFAULT_REDS = 7;
const DEFAULT_ASSASSINS = 1;
const DEFAULT_BLUES = 6;
const DEFAULT_YELLOWS =
  DEFAULT_ROWS * DEFAULT_COLS -
  DEFAULT_REDS -
  DEFAULT_BLUES -
  DEFAULT_ASSASSINS;

const DEFAULT_WORDS_PER_CARD = 2;
const DEFAULT_INCLUDE_EMOJIS = false;

const DEFAULT_SETTINGS = {
  alwaysShowWords: DEFAULT_ALWAYS_SHOW_WORDS,
  assassins: DEFAULT_ASSASSINS,
  blues: DEFAULT_BLUES,
  cols: DEFAULT_COLS,
  includeEmojis: DEFAULT_INCLUDE_EMOJIS,
  yellows: DEFAULT_YELLOWS,
  reds: DEFAULT_REDS,
  rows: DEFAULT_ROWS,
  wordsPerCard: DEFAULT_WORDS_PER_CARD,
};

function initGame(id: string) {
  updateGame(id, {
    phase: 'setup',
    settings: DEFAULT_SETTINGS,
  });
}

// TODO life the game, setGame logic out and have CodeNamesGame which knows game is not undefined
const CodeNames = ({ id, user }: { id: string; user: UserType }) => {
  const [game, setGame] = useState<IGameCodeNames>();
  const [prevPhase, setPrevPhase] = useState<GamePhase>();

  useEffect(() => {
    // if game changes in database then update state
    onGame(id, (game) => setGame(cast<IGameCodeNames>(game)));
  }, [id]);

  useEffect(() => {
    // if game exists but has no phase yet then initiate the game
    if (game && game.phase === undefined) {
      initGame(id);
    }
  }, [game, id]);

  useEffect(() => {
    if (game?.phase === prevPhase) return;
    // game phase changed...

    // Handle game over scenario
    if (game?.phase === 'game-over') {
      toast(`Game over!`);
      setPrevPhase(game?.phase);
      return;
    }

    if (typeof game?.phase !== 'undefined') {
      const phaseTeam = teamColorFromGamePhase(game?.phase);
      const phaseRole = teamRoleFromGamePhase(game?.phase);
      const isUserPhase =
        phaseTeam && isUser(phaseTeam) && phaseRole && isUser(phaseRole);

      if (isUserPhase) {
        toast(`Your turn, ${phaseTeam} ${phaseRole}!`);
      }
    }

    setPrevPhase(game?.phase);
  }, [game]);

  const teamsAndRoles: { blue: IRole[]; red: IRole[]; yellow: IRole[] } =
    useMemo(
      () =>
        game?.users &&
        Object.values(game?.users).reduce(
          (curr, user) => {
            if (!curr[user.team]) {
              curr[user.team] = [];
            }
            curr[user.team].push(user.role);
            return curr;
          },
          { blue: [], red: [], yellow: [] }
        ),
      [game]
    );

  // yellow/third team not required; 2 teams total (red and blue) required
  const canStartGame = useMemo(
    () =>
      teamsAndRoles?.red?.includes('clue-giver') &&
      teamsAndRoles?.red?.includes('guesser') &&
      teamsAndRoles?.blue?.includes('clue-giver') &&
      teamsAndRoles?.blue?.includes('guesser'),
    [teamsAndRoles]
  );

  // TODO this is a hack that works for now
  const gameHasThreeTeams = useMemo(
    () =>
      teamsAndRoles?.red?.length > 0 &&
      teamsAndRoles?.blue?.length > 0 &&
      teamsAndRoles?.yellow?.length > 0,
    [teamsAndRoles]
  );

  const handleStartGame = async () => {
    if (typeof game === 'undefined') {
      throw new Error(
        'Should not happen: attempted handleStartGame but game is undefined'
      );
    }

    const {
      assassins,
      blues,
      cols,
      includeEmojis,
      reds,
      rows,
      wordsPerCard,
      yellows,
    } = game?.settings;
    const totalCards = rows * cols;

    let candidateWordsList = [] as string[];
    await Promise.all([
      getWords((words) => {
        candidateWordsList.push(...words);
      }),
      includeEmojis &&
        getEmojis((emojis) => {
          candidateWordsList.push(...emojis);
        }),
    ]);
    const randomWords = getRandomArrayElements(
      candidateWordsList,
      totalCards * wordsPerCard
    );

    const scrambledOwners = getRandomArrayElements(
      [
        ...new Array(reds).fill('red'),
        ...new Array(blues).fill('blue'),
        ...new Array(assassins).fill('assassin'),
        ...new Array(yellows).fill('yellow'),
      ],
      totalCards
    );

    const board = {} as IBoard;
    // TODO maybe separate words from board layout unless we are making a world where card location/proximity matters
    for (let row = 0; row < rows; row++) {
      board[row] = {} as ICard[];
      for (let col = 0; col < cols; col++) {
        const words = [];
        for (let i = 0; i < wordsPerCard; i++) {
          words.push(randomWords.pop());
        }
        board[row][col] = {
          owner: scrambledOwners.pop(),
          revealed: false,
          word: words.join('\n'),
        };
      }
    }
    updateGame(
      id,
      {
        // @ts-ignore-next-line CodeNames game object has .board; TODO type for CodeNames game with board
        board: cast<IGame>(board),
        phase: nextGamePhase('setup', gameHasThreeTeams),
        status: 'playing',
      },
      () => toast('Game has started!')
    );
  };

  // TODO this should all be in a codenames context
  const [userRole, userTeam] = useMemo(() => {
    const gameUser = game?.users && game?.users[user.uid];
    return [gameUser?.role, gameUser?.team];
  }, [game, user]);
  const isUser = (roleOrTeam?: IRole | TeamColor) =>
    [userRole, userTeam].includes(roleOrTeam);

  const isCluePhase = useMemo(
    () =>
      ['first-team-clue', 'second-team-clue', 'third-team-clue'].includes(
        // @ts-ignore-next-line refactor so this component always has game well-defined
        game?.phase
      ),
    [game]
  );
  const isGuessingPhase = useMemo(
    () =>
      [
        'first-team-guessing',
        'second-team-guessing',
        'third-team-guessing',
        // @ts-ignore-next-line refactor so this component always has game well-defined
      ].includes(game?.phase),
    [game]
  );
  // @ts-ignore-next-line refactor so this component always has game well-defined
  const phaseTeam = useMemo(() => teamColorFromGamePhase(game?.phase), [game]);
  const nextPhase = useMemo(
    // @ts-ignore-next-line refactor so this component always has game well-defined
    () => nextGamePhase(game?.phase, gameHasThreeTeams),
    [game, gameHasThreeTeams]
  );

  if (!user)
    return (
      <Card title="Requires Login">Please login to use this feature.</Card>
    );

  return (
    <Tabs defaultActiveKey="game">
      <Tab eventKey="game" title="Play">
        <TeamAndRole
          // @ts-ignore-next-line TODO fix this
          game={game}
          gameId={id}
          isUser={isUser}
          userRole={userRole}
          userTeam={userTeam}
        />
        <PhaseDisplay
          // @ts-ignore-next-line refactor so this component always has game well-defined
          phase={game?.phase}
          winner={game?.winner}
        />
        <Card>
          {game?.phase === 'setup' && (
            <div>
              <Button onClick={handleStartGame} disabled={!canStartGame}>
                Start Game
              </Button>
              {user.admin && (
                <Button
                  onClick={handleStartGame}
                  variant="danger"
                  className="ms-3"
                >
                  Admin Start Game
                </Button>
              )}
            </div>
          )}
          {isCluePhase && (
            <>
              {isUser(phaseTeam) && isUser('clue-giver') && (
                <ClueForm
                  submitClue={(clue) =>
                    updateGame(id, {
                      ...game,
                      // @ts-ignore-next-line TODO fix this
                      clue,
                      guessesMadeThisTurn: 0,
                      phase: nextPhase,
                    })
                  }
                />
              )}
              <Board
                // @ts-ignore-next-line refactor so this component always has game well-defined
                board={game?.board}
                displayAllCardOwnersByColor={isUser('clue-giver')}
                // @ts-ignore-next-line refactor so this component always has game well-defined
                displayWordForRevealedCards={game?.settings?.alwaysShowWords}
              />
              {user.admin && (
                <AdminNextPhaseButton
                  onClick={() =>
                    updateGame(id, {
                      ...game,
                      phase: nextPhase,
                    })
                  }
                />
              )}
            </>
          )}
          {isGuessingPhase && (
            <>
              <ClueCard
                // @ts-ignore-next-line refactor so this component always has game well-defined
                clue={game?.clue}
                // @ts-ignore-next-line TODO fix this
                teamColor={phaseTeam}
                // @ts-ignore-next-line TODO fix this
                guessesMadeThisTurn={game?.guessesMadeThisTurn}
              />
              <Board
                // @ts-ignore-next-line refactor so this component always has game well-defined
                board={game?.board}
                canClick={isUser(phaseTeam) && isUser('guesser')}
                onClick={(i, j) => {
                  if (!isUser(phaseTeam) || !isUser('guesser')) return;
                  if (!window.confirm('Confirm selection?')) {
                    return;
                  }
                  const newGame = { ...game };
                  // @ts-ignore-next-line TODO fix this
                  newGame.board[i][j].revealed = true;
                  // @ts-ignore-next-line refactor so this component always has game well-defined
                  newGame.guessesMadeThisTurn = game?.guessesMadeThisTurn + 1;
                  if (
                    // @ts-ignore-next-line refactor so this component always has game well-defined
                    game?.clue.number > 0 &&
                    // @ts-ignore-next-line refactor so this component always has game well-defined
                    newGame.guessesMadeThisTurn >= game?.clue.number + 1
                  ) {
                    newGame.phase = nextPhase;
                  }
                  if (
                    // TODO core jank
                    game?.board[i][j].owner !== phaseTeam
                  ) {
                    newGame.phase = nextPhase;
                  }
                  if (game?.board[i][j].owner === 'assassin') {
                    newGame.phase = 'game-over';
                    newGame.status = 'done';
                    // TODO newGame.winner =
                    // @ts-ignore-next-line refactor so this component always has game well-defined
                  } else if (isGameOver(game?.board)) {
                    newGame.phase = 'game-over';
                    newGame.status = 'done';
                    newGame.winner = phaseTeam;
                  }
                  updateGame(id, newGame);
                }}
                displayAllCardOwnersByColor={isUser('clue-giver')}
                // @ts-ignore-next-line refactor so this component always has game well-defined
                displayWordForRevealedCards={game?.settings?.alwaysShowWords}
              />
              {isUser(phaseTeam) && isUser('guesser') && (
                <EndTurnButton
                  onClick={() =>
                    updateGame(id, {
                      ...game,
                      phase: nextPhase,
                    })
                  }
                />
              )}
              {user.admin && (
                <AdminNextPhaseButton
                  onClick={() =>
                    updateGame(id, {
                      ...game,
                      phase: nextPhase,
                    })
                  }
                />
              )}
            </>
          )}
          {game?.phase === 'game-over' && (
            <>
              <Card
                title={`${game?.winner} Team Wins!`}
                bg={styleVariantFromTeamColor(game?.winner)}
              >
                Congratulation, {game?.winner} team. You win!
              </Card>
              <Board
                board={game?.board}
                canClick={false}
                displayAllCardOwnersByColor={true}
                displayWordForRevealedCards={true}
                onClick={() => {}}
              />
            </>
          )}
        </Card>
      </Tab>
      <Tab eventKey="settings" title="Settings">
        <Card title="Game">
          <ListGroup>
            <ListGroup.Item>
              {/* TODO add shareable link */}
              <strong>Id:</strong> {id}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Type:</strong> {game?.type}
            </ListGroup.Item>
            <ListGroup.Item
              // @ts-ignore-next-line refactor so this component always has game well-defined
              variant={variantFromGameStatus(game?.status)}
            >
              <strong>Status:</strong> {game?.status}
            </ListGroup.Item>
          </ListGroup>
        </Card>
        <Settings
          // @ts-ignore-next-line refactor so this component always has game well-defined
          game={game}
          gameId={id}
        />
      </Tab>
    </Tabs>
  );
};

export default CodeNames;

import firebase, { db } from '../components/firebase.js';

//TODO lift these to top-level types definitions file
type Uid = string;
type PostId = string;
type TimestampFromFirebase = number | { seconds: number; nanoseconds: number };
type TimestampToFirebase =
  | typeof firebase.database.ServerValue.TIMESTAMP
  | typeof firebase.firestore.FieldValue.serverTimestamp;
interface PostForDatabase {
  content: string;
  modalities?: { [modalityKey: string]: { name: string } };
  timestamp: TimestampToFirebase;
}
interface PostFromDatabase {
  content: string;
  modalities?: { [modalityKey: string]: { name: string } };
  timestamp: TimestampFromFirebase;
}
export interface Post {
  id: PostId;
  content: string;
  modalities?: { [modalityKey: string]: { name: string } };
  timestamp: Date;
}
export interface Posts {
  [postId: PostId]: Post;
}

//TODO tests for this
//TODO use and do this everywhere at api level, for public posts too, then deprecate shared/timestamp
const dateFromFirebaseTimestamp: object | undefined = (
  timestamp: TimestampFromFirebase
) => {
  if (typeof timestamp === 'object' && typeof timestamp.seconds === 'number') {
    return new Date(timestamp.seconds * 1000);
  }
  if (typeof timestamp === 'number') {
    return new Date(timestamp);
  }
  return undefined;
};

const privatePostsRef = ({ uid }: { uid: Uid }) =>
  db
    .collection('posts')
    .doc('private')
    .collection('users')
    .doc(uid)
    .collection('posts');
const privatePostRef = ({ uid, postId }: { uid: Uid; postId: PostId }) =>
  privatePostsRef({ uid }).doc(postId);

export const getPrivatePosts = (
  { uid }: { uid: Uid },
  callback = (posts: Posts) => null
) => {
  const posts: Posts = {};
  privatePostsRef({ uid })
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((post) => {
        //@ts-ignore-next-line
        posts[post.id] = {
          ...post.data(),
          id: post.id,
          //@ts-ignore-next-line
          timestamp: dateFromFirebaseTimestamp(post.data().timestamp),
        };
      });
      callback && typeof callback === 'function' && callback(posts);
    });
};

export const createPrivatePost = (
  { uid, post }: { uid: Uid; post: PostForDatabase },
  callback = () => null
) => {
  if (!uid || !post) return;
  post.timestamp = firebase.firestore.FieldValue.serverTimestamp();
  privatePostsRef({ uid })
    .doc()
    .set(post)
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};

export const deletePrivatePost = (
  { uid, postId }: { uid: Uid; postId: PostId },
  callback = () => null
) => {
  if (!uid || !postId) return;
  privatePostRef({ uid, postId })
    .delete()
    .then(() => {
      callback && typeof callback === 'function' && callback();
    });
};

import { useContext, useState, useCallback, useEffect } from 'react';
import AppContext from '../../../../AppContext';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Lists from './Lists';
import Editor, { DEFAULT_LIST_ID } from './Editor';
import Help from './Help';
import { createList, getLists } from './api/lists-api';

function ListsPage() {
  const { user } = useContext(AppContext);

  const [lists, setLists] = useState({});
  useEffect(() => {
    async function fetchData() {
      if (!user?.uid) {
        return;
      }
      const lists = await getLists(user.uid);
      setLists(lists);
    }
    fetchData();
  }, [user?.uid]);

  const [listId, setListId] = useState(
    window.localStorage.getItem('lists-list-id') || DEFAULT_LIST_ID
  );
  const [key, setKey] = useState('lists');

  const updateLists = useCallback((callback = () => null, lists) => {
    setLists(lists);
    callback(Object.values(lists).map((list) => list.id));
  }, []);

  const loadLists = useCallback(
    (callback = () => null) => {
      getLists(user.uid, (lists) => updateLists(callback, lists));
    },
    [user.uid, updateLists]
  );

  const storeListId = useCallback((listId) => {
    setListId(listId);
    window.localStorage.setItem('lists-list-id', listId);
  }, []);

  const switchToEditor = useCallback(() => {
    setKey('editor');
  }, []);

  const selectListId = useCallback(
    (listId) => {
      storeListId(listId);
      switchToEditor();
    },
    [storeListId, switchToEditor]
  );

  const createAndSelectList = useCallback(
    (name, onSuccess) => {
      createList(user.uid, name, (listId) => {
        loadLists();
        selectListId(listId);
        onSuccess();
      });
    },
    [user.uid, loadLists, selectListId]
  );

  return (
    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
      <Tab eventKey="lists" title="Lists">
        <Lists
          createList={createAndSelectList}
          currentListId={listId}
          lists={lists}
          selectListId={selectListId}
        />
      </Tab>
      <Tab eventKey="editor" title="Editor">
        <Editor
          listId={listId}
          lists={lists}
          loadLists={loadLists}
          selectListId={selectListId}
        />
      </Tab>
      <Tab eventKey="help" title="Help">
        <Help />
      </Tab>
    </Tabs>
  );
}

export { ListsPage };

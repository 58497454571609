import ChessGPTv1 from './ChessGPTv1';
import { Route, Switch } from 'react-router-dom';
import ChessGPTv2 from './ChessGPTv2';
import ChessGPTv3 from './ChessGPTv3';

function ChessGPTHeader() {
  return (
    <>
      <div>
        <h1>ChessGPT</h1>
        <a href="/chessgpt">home</a>
      </div>
      <br />
    </>
  );
}

function ChessGPT() {
  return (
    <>
      <ChessGPTHeader />
      <Switch>
        <Route path="/chessgpt/v1" key="v1">
          <ChessGPTv1 />
        </Route>
        <Route path="/chessgpt/v2" key="v2">
          <ChessGPTv2 />
        </Route>
        <Route path="/chessgpt/v3" key="v3">
          <ChessGPTv3 />
        </Route>
        <Route path="/">
          <div>Select a game version</div>
          <ul>
            <li>
              <a href="/chessgpt/v1">v1</a>
            </li>
            <li>
              <a href="/chessgpt/v2">v2</a>
            </li>
            <li>
              <a href="/chessgpt/v3">v3</a>
            </li>
          </ul>
        </Route>
      </Switch>
    </>
  );
}
export default ChessGPT;

import React, { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link, Route, Switch } from 'react-router-dom';
import AppNav from './AppNav';
import logoImg from './logo192.png';
import Notifications from './Notifications';
import AppContext from '../AppContext';
import { UserPhoto } from '../shared/User';
import MyDropdownToggle from '../shared/MyDropdownToggle';
import Wizard from '../Wizard';
import HowToGetPremium from '../shared/Premium/HowToGetPremium';
import { LogInButton } from './LogInButton';

const Beta = () => (
  <>
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover>
          <Popover.Header as="h3">βeta</Popover.Header>
          <Popover.Body>
            <p>Hi, friends!</p>
            <p>
              xBook is a work in progress.
              <br />
              Our developer is hard at work.
            </p>
            <p>
              We appreciate your patience, feedback, and support in building our
              healthy community together.
            </p>
            <p>Please enjoy looking around, posting, and playing.</p>
            <p>— Geoff, The Developer</p>
          </Popover.Body>
        </Popover>
      }
    >
      <div style={{ display: 'inline-block' }}>βeta</div>
    </OverlayTrigger>
  </>
);

function AccountDropdownMenu({ logout }: { logout: () => void }) {
  const { user } = useContext(AppContext);

  if (!user) {
    return null;
  }

  return (
    <Dropdown>
      <MyDropdownToggle />
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/about">
          About
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/donate">
          Donate
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/invitecodes">
          Invite Codes
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/premium">
          Premium
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/settings">
          Settings
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as="button" onClick={logout}>
          Log Out
        </Dropdown.Item>
        {user.admin && (
          <>
            <Dropdown.Divider />
            <Dropdown.Header>Admin Section</Dropdown.Header>
            <Dropdown.Item as={Link} to="/admin">
              Admin
            </Dropdown.Item>
            {/* TODO: this is weak security measure until all pages are buttoned up for user state logged|premium|admin */}
            <Dropdown.Item as={Link} to="/sitemap">
              Sitemap
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function TopRightMenuItem({ children }: { children: React.ReactNode }) {
  return (
    <div className="ms-3" style={{ display: 'inline-block' }}>
      {children}
    </div>
  );
}

export const TopRightMenu = () => {
  const { logout, user } = useContext(AppContext);
  if (user) {
    return (
      <div className="float-end">
        {!user.isPremium && (
          <TopRightMenuItem>
            <HowToGetPremium />
          </TopRightMenuItem>
        )}
        <TopRightMenuItem>
          <Wizard icon={true} />
        </TopRightMenuItem>
        <TopRightMenuItem>
          <Notifications />
        </TopRightMenuItem>
        <TopRightMenuItem>
          <UserPhoto uid={user.uid} />
        </TopRightMenuItem>
        <TopRightMenuItem>
          <AccountDropdownMenu logout={logout} />
        </TopRightMenuItem>
      </div>
    );
  }
  return (
    <TopRightMenuItem>
      <LogInButton />
    </TopRightMenuItem>
  );
};

const AppHeaderTitle = () => {
  return (
    <div style={{ display: 'inline-block' }}>
      <Link
        to="/"
        style={{
          color: 'inherit',
          textDecoration: 'inherit',
        }}
      >
        <span title="xBook" className="h1">
          <img
            src={logoImg}
            alt="xBook logo"
            style={{ verticalAlign: 'bottom', height: '3rem' }}
          />
          <span className="ms-2">xBook</span>
        </span>
      </Link>
      {/* <span className="text-muted ms-2">
        <Beta />
      </span> */}
      <span className="text-muted ms-2 d-none d-sm-inline">
        <span style={{ fontWeight: 300 }}>
          healthy online community & games
        </span>
      </span>
    </div>
  );
};

const AppHeader = () => {
  const { user } = useContext(AppContext);
  return (
    <Switch>
      <Route exact path="/happy-island" key={'happy-island'} />
      <Route
        path="/"
        key="any"
        render={() => (
          <header>
            <Card>
              <Card.Body>
                <AppHeaderTitle />
                <div className="float-end">
                  <TopRightMenu />
                </div>
              </Card.Body>
            </Card>
            {user && (
              <Card className="mt-1">
                <Card.Body
                  style={{
                    paddingBottom: '0.25rem',
                    paddingTop: '0.25rem',
                  }}
                >
                  <AppNav />
                </Card.Body>
              </Card>
            )}
          </header>
        )}
      />
    </Switch>
  );
};

export default AppHeader;

import { Relationship } from '../types';

/**
 *
 * @param {Object} relationships
 * @param {Object} [items]
 * @returns {Array} itemsArray
 */
const getItemsArrayFromRelationships = (relationships = {}, items = {}) =>
  Object.values(relationships).reduce((itemsArray, relationship) => {
    relationship.options.forEach((option) => {
      if (itemsArray.indexOf(option) === -1) {
        itemsArray.push(option);
      }
    });
    return itemsArray;
  }, Object.keys(items));

// TODO how do we want to handle contradictory relationships and rock paper scissor loops ??
const LOOP_LIMIT = 100;

type ItemIdsByRank = {
  [rank: number]: string[];
};

function getItemIdsByRank(
  relationships: { [relationshipId: string]: Relationship },
  items: { [itemId: string]: Item } = {}
): { itemIds: ItemIdsByRank; errorMessage: string } {
  const itemsByRank = {
    0: getItemsArrayFromRelationships(relationships, items),
  };

  let key = 0;
  let noChangesThisTime = false;

  while (!noChangesThisTime) {
    const thisRankItems = itemsByRank[key];
    const itemsToLift = [];

    thisRankItems.forEach((item) => {
      Object.values(relationships).forEach((relationship) => {
        if (relationship.selection === item) {
          const unselectedOption = relationship.options.filter(
            (item) => item !== relationship.selection
          )[0];
          if (thisRankItems.includes(unselectedOption)) {
            if (itemsToLift.indexOf(item) === -1) {
              itemsToLift.push(item);
            }
          }
        }
      });
    });

    itemsByRank[key] = thisRankItems.filter(
      (item) => !itemsToLift.includes(item)
    );

    if (itemsToLift.length !== 0) {
      itemsByRank[+key + 1] = itemsToLift;
    } else {
      noChangesThisTime = true;
    }

    key++;

    if (key > LOOP_LIMIT) {
      return {
        errorMessage:
          'ERROR: INFINITE LOOP DETECTED\nDELETE RELATIONSHIPS AS NEEDED',
        itemIds: [],
      };
    }
  }

  return { itemIds: itemsByRank };
}

export default getItemIdsByRank;

import { Id, List } from '../types';
import { listRef, listsRef } from './db-refs';

function listHasName(list: List) {
  return typeof list.name !== 'undefined';
}

function setListName({
  uid,
  listId,
  name,
}: {
  uid: Id;
  listId: Id;
  name: string;
}) {
  listRef({ uid, listId }).set({ name }, { merge: true });
}

// handles and fixes old lists with no name
// TODO fix all existing lists and delete this code
function fixList({ uid, list }: { uid: Id; list: List & { id: Id } }) {
  if (!listHasName(list)) {
    setListName({ uid, listId: list.id, name: list.id });
  }
}

type ListWithId = List & { id: Id };
type ListsWithIdByListId = { [listId: Id]: ListWithId };

async function getLists(
  uid: Id,
  callback?: (lists: ListsWithIdByListId) => void
): Promise<ListsWithIdByListId> {
  const lists = {} as ListsWithIdByListId;
  await listsRef({ uid })
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((list) => {
        const listWithId = { id: list.id, ...list.data() } as ListWithId;
        lists[list.id] = listWithId;

        // should never need to do this:
        fixList({ uid, list: listWithId });
      });
    });
  // TODO shouldn't need to handle any callbacks ever
  callback && typeof callback === 'function' && callback(lists);
  return lists;
}

function createList(
  userId: Id,
  name: string,
  callback: (docRefId: Id) => void
) {
  if (!userId) return;
  listsRef({ uid: userId })
    .add({
      name,
    })
    .then((docRef) => {
      callback && typeof callback === 'function' && callback(docRef.id);
    });
}

/**
 * TODO in reviewing db, this appears it only deletes the middle node; need to delete downstream childrens too
 * https://firebase.google.com/docs/firestore/manage-data/delete-data#collections
 */
function deleteList(userId: Id, listId: Id, callback: () => void) {
  if (!userId || !listId) return;
  listRef({ uid: userId, listId }).delete();
  callback && typeof callback === 'function' && callback();
}

export { createList, deleteList, getLists };
